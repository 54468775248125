import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bs_btn = _resolveComponent("bs-btn")!
  const _component_session_editor = _resolveComponent("session-editor")!

  return (_openBlock(), _createBlock(_component_session_editor, {
    modelValue: _ctx.session,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.session) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_bs_btn, {
        variant: "success",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.createSession()))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Create")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}